@charset "UTF-8";
@import url(//cdn.jsdelivr.net/npm/yakuhanjp@3.0.0/dist/css/yakuhanjp.min.css);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,500);
html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit; }

html {
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold; }

body {
  font-family: YakuHanJP,'Roboto' ,"Yu Gothic", YuGothic, sans-serif;
  font-weight: normal;
  font-weight: 400;
  color: #222;
  font-size: 1.4rem;
  line-height: 1.8;
  -webkit-text-size-adjust: 100%; }

@media only screen and (min-width: 767px) {
  body {
    font-size: 1.6rem; } }

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
全頁共通css
–––––––––––––––––––––––––––––––––––––––––––––––––– */
h1, h2, h3, h4, h5, h6, strong, .font_bold {
  clear: both;
  font-weight: 500; }

p {
  line-height: 1.8; }

img {
  height: auto;
  vertical-align: bottom;
  max-width: 100%; }

a {
  color: #222;
  outline: none;
  text-decoration: none; }
  a:hover {
    color: #222; }

strong {
  font-weight: bold; }

small {
  font-size: 1.2rem; }

address {
  font-style: normal; }

blockquote {
  background-color: #eee;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 15px;
  padding: 30px;
  position: relative; }

blockquote:before {
  content: "“";
  font-size: 600%;
  line-height: 1em;
  font-family: "ＭＳ Ｐゴシック",sans-serif;
  color: #999;
  position: absolute;
  left: 0;
  top: 0; }

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
管理画面でつけるclassようのcss
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.aligncenter {
  display: block;
  margin: 0 auto; }

.alignright {
  float: right;
  margin: 0 0 20px 30px;
  padding: 0; }

.alignleft {
  float: left;
  margin: 0 30px 20px 0;
  padding: 0; }

article h1, article h2, article h3, article h4, article h5, article h6 {
  clear: both; }

img[class*="wp-image-"],
img[class*="attachment-"] {
  height: auto;
  max-width: 100%; }

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
全頁共通PC/SP切り替えcss
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.sp_none {
  display: none; }

.pc_none {
  display: block; }

@media only screen and (min-width: 767px) {
  .sp_none {
    display: block; }
  .pc_none {
    display: none; } }

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
全頁共通サイト幅css
–––––––––––––––––––––––––––––––––––––––––––––––––– */
main #wrap {
  padding: 0 30px 60px; }

@media only screen and (min-width: 767px) {
  main #wrap {
    padding: 0 30px 120px;
    margin: 0 auto;
    max-width: 1080px; } }

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
編集ボタン
–––––––––––––––––––––––––––––––––––––––––––––––––– */
#webmaster {
  margin-top: 30px;
  text-align: center; }
  #webmaster a {
    background-color: #eee;
    border-radius: 6px;
    padding: 10px 15px;
    display: block; }

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
全頁共通レイアウト
–––––––––––––––––––––––––––––––––––––––––––––––––– */
main a {
  border-bottom: dotted 1px #222; }

main a img {
  border: none;
  margin-bottom: -1px; }

main p + p {
  margin-top: 15px; }

main ul, main ol {
  margin-top: 15px; }
  main ul li, main ol li {
    margin-bottom: 5px;
    margin-left: 20px; }
  main ul + p, main ol + p {
    margin-top: 15px; }

main table {
  border-top: solid 1px #d6d6d6;
  border-left: solid 1px #d6d6d6;
  margin: 15px auto;
  width: 100%; }
  main table th, main table td {
    border-bottom: solid 1px #d6d6d6;
    border-right: solid 1px #d6d6d6;
    display: table-cell;
    max-width: 530px;
    padding: 10px 15px; }
  main table th {
    background-color: #efefef;
    font-weight: 500;
    min-width: 100px;
    text-align: left; }
  main table.free_table {
    border: 0; }
    main table.free_table th, main table.free_table td {
      border: 0; }

main h1 {
  font-size: 2rem; }

main h2, main h3, main h4, main h5, main h6 {
  font-weight: 600;
  margin-bottom: 15px; }

main h2 {
  background-color: #444444;
  color: white;
  font-size: 1.8rem;
  padding: 10px 20px; }

main h3 {
  border-bottom: solid 1px #222222;
  display: inline-block;
  font-size: 1.7rem;
  padding-bottom: 5px; }

main h4 {
  font-size: 1.5rem; }

main h5 {
  font-size: 1.5rem; }

main h6 {
  font-size: 1.4rem; }

main p + h2, main h3, main h4, main h5, main h6 {
  margin-top: 20px; }

main ul + h2, main h3, main h4, main h5, main h6 {
  margin-top: 20px; }

main ol + h2, main h3, main h4, main h5, main h6 {
  margin-top: 20px; }

main div + h2, main h3, main h4, main h5, main h6 {
  margin-top: 20px; }

main hr {
  background-color: #ccc;
  border: none;
  clear: both;
  height: 2px;
  margin: 15px 0; }

main ul li {
  list-style: disc; }

main ol li {
  list-style-type: decimal; }

main .column_box {
  margin-bottom: 30px; }
  main .column_box .col .col_img {
    margin-top: 10px; }

main .btn_link {
  background-color: #1293db;
  border: none;
  color: white;
  display: block;
  font-size: 1.5rem;
  height: 44px;
  line-height: 44px;
  text-align: center;
  width: 200px; }

main .txt_link {
  border: none;
  color: #1293db; }

main dl dt {
  display: inline-block;
  margin-top: 15px;
  padding: 5px 0; }

main dl dd {
  margin-left: 15px;
  padding: 5px 0; }

@media only screen and (min-width: 767px) {
  main p + p {
    margin-top: 20px; }
  main ul, main ol {
    margin-top: 20px; }
    main ul li, main ol li {
      margin-bottom: 5px;
      margin-left: 25px; }
    main ul + p, main ol + p {
      margin-top: 20px; }
  main p + h2, main h3, main h4, main h5, main h6 {
    margin-top: 30px; }
  main ul + h2, main h3, main h4, main h5, main h6 {
    margin-top: 30px; }
  main ol + h2, main h3, main h4, main h5, main h6 {
    margin-top: 30px; }
  main div + h2, main h3, main h4, main h5, main h6 {
    margin-top: 30px; }
  main h1 {
    font-size: 3.2rem; }
  main h2, main h3, main h4, main h5, main h6 {
    margin-bottom: 20px; }
  main h2 {
    font-size: 2rem; }
  main h3 {
    font-size: 1.8rem;
    padding-bottom: 8px; }
  main h4 {
    font-size: 1.6rem; }
  main h5 {
    font-size: 1.5rem; }
  main h6 {
    font-size: 1.4rem; }
  main .column_box {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    margin-bottom: 50px; }
    main .column_box .col .col_img {
      margin-top: 20px; }
      main .column_box .col .col_img img {
        max-height: 360px; }
    main .column_box.col_2 .col {
      width: calc(100% / 2 - 30px); }
      main .column_box.col_2 .col:nth-child(n+3) {
        margin-top: 40px; }
    main .column_box.col_3 .col {
      width: calc(100% / 3 - 40px); }
      main .column_box.col_3 .col:nth-child(n+4) {
        margin-top: 40px; }
    main .column_box.col_3:after {
      content: "";
      display: block;
      width: calc(100% / 3 - 40px); }
  main dl {
    display: flex;
    flex-flow: wrap; }
    main dl dt {
      border-bottom: solid 1px #dddddd;
      margin: 0;
      padding: 10px 0;
      width: 10%; }
    main dl dd {
      border-bottom: solid 1px #dddddd;
      padding: 10px 0 10px 30px;
      margin: 0;
      width: 90%; }
    main dl dt:last-child {
      background: red;
      border: none; } }

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
全頁共通デフォルトギャラリー
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.gallery {
  display: flex;
  flex-flow: wrap row;
  justify-content: space-between;
  margin: 15px 0; }
  .gallery br {
    display: none; }
  .gallery .gallery-item {
    overflow: hidden;
    position: relative; }
    .gallery .gallery-item .gallery-icon a {
      border: none;
      display: block;
      overflow: hidden;
      margin: 1px;
      position: relative; }
      .gallery .gallery-item .gallery-icon a:before {
        content: "";
        display: block;
        padding-top: 100%; }
      .gallery .gallery-item .gallery-icon a img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%; }
    .gallery .gallery-item .gallery-caption {
      background: rgba(0, 0, 0, 0.6);
      color: white;
      padding: 5px;
      pointer-events: none;
      position: absolute;
      left: 1px;
      right: 1px;
      bottom: 0; }
  .gallery.gallery-size-thumbnail .gallery-item .gallery-icon a:before {
    padding-top: 100%; }
  .gallery.gallery-columns-1 .gallery-item {
    width: 100%; }
  .gallery.gallery-columns-2 .gallery-item {
    width: calc(100% / 2); }
  .gallery.gallery-columns-3 .gallery-item {
    width: calc(100% / 2); }
  .gallery.gallery-columns-4 .gallery-item {
    width: calc(100% / 2); }
  .gallery.gallery-columns-5 .gallery-item {
    width: calc(100% / 2); }
  .gallery.gallery-columns-6 .gallery-item {
    width: calc(100% / 2); }
  .gallery.gallery-columns-7 .gallery-item {
    width: calc(100% / 2); }
  .gallery.gallery-columns-8 .gallery-item {
    width: calc(100% / 2); }
  .gallery.gallery-columns-9 .gallery-item {
    width: calc(100% / 2); }

@media only screen and (min-width: 767px) {
  .gallery {
    margin: 20px 0; }
    .gallery .gallery-item .gallery-caption {
      font-size: 1.3rem; }
    .gallery.gallery-columns-1 .gallery-item {
      width: 100%; }
    .gallery.gallery-columns-2 .gallery-item {
      width: calc(100% / 2); }
    .gallery.gallery-columns-3 .gallery-item {
      width: calc(100% / 3); }
    .gallery.gallery-columns-4 .gallery-item {
      width: calc(100% / 4); }
    .gallery.gallery-columns-5 .gallery-item {
      width: calc(100% / 5); }
    .gallery.gallery-columns-6 .gallery-item {
      width: calc(100% / 6); }
    .gallery.gallery-columns-7 .gallery-item {
      width: calc(100% / 7); }
    .gallery.gallery-columns-8 .gallery-item {
      width: calc(100% / 8); }
    .gallery.gallery-columns-9 .gallery-item {
      width: calc(100% / 9); } }
