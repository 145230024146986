@charset "utf-8";

html {
  -webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}
*, *:before, *:after {
  -webkit-box-sizing: inherit;
	   -moz-box-sizing: inherit;
	        box-sizing: inherit;
}
html {
	font-size: 62.5%;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
@import url(//cdn.jsdelivr.net/npm/yakuhanjp@3.0.0/dist/css/yakuhanjp.min.css);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,500);

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold;
}

body  {
  font-family: YakuHanJP,'Roboto' ,"Yu Gothic", YuGothic, sans-serif;
  font-weight: normal;
  font-weight: 400;
  color: #222;
  font-size: 1.4rem;
  line-height: 1.8;
  // overflow-y:scroll;
  -webkit-text-size-adjust: 100%;
}
@media only screen and (min-width: 767px) {
  body  {
    font-size:1.6rem;
  }
}

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
全頁共通css
–––––––––––––––––––––––––––––––––––––––––––––––––– */

  h1,h2,h3,h4,h5,h6,strong,.font_bold{
    clear:both;
    font-weight: 500;
  }
  p{
    line-height:1.8;
  }

  img{
    height:auto;
    vertical-align: bottom;
    max-width: 100%;
  }

  a {
    color: #222;
    outline: none;
    text-decoration: none;
    &:hover{
      color: #222;
    }
  }
  strong{
    font-weight:bold;
  }
  small{
    font-size:1.2rem;
  }

  address{
    font-style: normal;
  } 
  blockquote {
    background-color: #eee;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom:15px;
    padding: 30px;
    position: relative;
  }

  blockquote:before {
    content: "“";
    font-size: 600%;
    line-height: 1em;
    font-family: "ＭＳ Ｐゴシック",sans-serif;
    color: #999;
    position: absolute;
    left: 0;
    top: 0;
  }


 /* ––––––––––––––––––––––––––––––––––––––––––––––––––
管理画面でつけるclassようのcss
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.aligncenter {
    display: block;
    margin: 0 auto;
}

.alignright { 
  float: right;
  margin:0 0 20px 30px;
  padding:0;
}

.alignleft { 
  float: left;
  margin:0 30px 20px 0;
  padding:0;
}

article h1,article h2,article h3,article h4,article h5,article h6{
  clear: both;
} 
 
img[class*="wp-image-"],
img[class*="attachment-"] {
    height: auto;
    max-width: 100%;
}

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
全頁共通PC/SP切り替えcss
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.sp_none{
  display:none;
}
.pc_none{
  display:block;
}
@media only screen and (min-width: 767px) {
  .sp_none{
    display:block;
  }
  .pc_none{
    display:none;
  }
}


/* ––––––––––––––––––––––––––––––––––––––––––––––––––
全頁共通サイト幅css
–––––––––––––––––––––––––––––––––––––––––––––––––– */
main{
  #wrap{
    padding: 0 30px 60px;
  }
}
@media only screen and (min-width: 767px) {
  main{
    #wrap{
      padding: 0 30px 120px;
      margin: 0 auto;
      max-width:1080px;
    }
  }
}

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
編集ボタン
–––––––––––––––––––––––––––––––––––––––––––––––––– */
#webmaster{
  margin-top:30px;
  text-align: center;
  a{
    background-color: #eee;
    border-radius: 6px;
    padding: 10px 15px;
    display:block;
  }
}


/* ––––––––––––––––––––––––––––––––––––––––––––––––––
全頁共通レイアウト
–––––––––––––––––––––––––––––––––––––––––––––––––– */

main{
  a {
    border-bottom: dotted 1px #222;
  }

  a img {
    border:none;
    margin-bottom: -1px ;
  }
  p{
    &+p{
      margin-top:15px
    }
  }

  ul,ol{
    margin-top:15px;
    li{
      margin-bottom:5px;
      margin-left:20px;
    }
    &+p{
      margin-top:15px
    }
  }
  table{
    border-top: solid 1px #d6d6d6;
    border-left: solid 1px #d6d6d6;
    margin: 15px auto;
    width: 100%;
    th,td{
      border-bottom: solid 1px #d6d6d6;
      border-right: solid 1px #d6d6d6;
      display:table-cell;
      max-width:530px;
      padding:10px 15px;
      &:last-child{
      }
    }
    th{
      background-color:#efefef;
      font-weight: 500;
      min-width:100px;
      text-align:left;
    }
    td{
    }
    &.free_table{
      border:0;
      th,td{
        border:0;
      }
    }
  }


  h1{
    font-size: 2rem;
  }

  h2,h3,h4,h5,h6{
    font-weight:600;
    margin-bottom:15px;
  }
  h2{
    background-color:#444444;
    color:white;
    font-size:1.8rem;
    padding:10px 20px;
  }
  h3{
    border-bottom:solid 1px #222222;
    display:inline-block;
    font-size:1.7rem;
    padding-bottom: 5px;
  }
  h4{
    font-size: 1.5rem;
  }
  h5{
    font-size: 1.5rem;
  }
  h6{
    font-size:1.4rem;
  }
  p + h2,h3,h4,h5,h6{
    margin-top:20px;
  }
  ul + h2,h3,h4,h5,h6{
    margin-top:20px;
  }
  ol + h2,h3,h4,h5,h6{
    margin-top:20px;
  }
  div + h2,h3,h4,h5,h6{
    margin-top:20px;
  }
  hr{
    background-color:#ccc;
    border: none;
    clear: both;
    height:2px;
    margin: 15px 0;
  }
  ul{
    li{
      list-style:disc;
    }
  }
  ol{
    li{
        list-style-type: decimal;
    }
  }
  .column_box{
    margin-bottom:30px;
    .col{
      .col_img{
        margin-top:10px;
      }
    }
  }

  .btn_link{
    background-color:#1293db;
    border:none;
    color:white;
    display:block;
    font-size:1.5rem;
    height:44px;
    line-height:44px;
    text-align:center;
    width:200px;
  }
  .txt_link{
    border:none;
    color:#1293db;
  }
  dl{
    dt{
      display: inline-block;
      margin-top: 15px;
      padding: 5px 0;
    }
    dd{
      margin-left:15px;
      padding: 5px 0;
    }
  }

}
@media only screen and (min-width: 767px) {
  main{
    p{
      &+p{
        margin-top:20px
      }
    }
    ul,ol{
      margin-top:20px;
      li{
        margin-bottom:5px;
        margin-left:25px;
      }
      &+p{
        margin-top:20px
      }
    }
    p + h2,h3,h4,h5,h6{
      margin-top:30px;
    }
    ul + h2,h3,h4,h5,h6{
      margin-top:30px;
    }
    ol + h2,h3,h4,h5,h6{
      margin-top:30px;
    }
    div + h2,h3,h4,h5,h6{
      margin-top:30px;
    }
    h1{
      font-size: 3.2rem;
    }
    h2,h3,h4,h5,h6{
      margin-bottom:20px;
    }
    h2{
      font-size:2rem;
    }
    h3{
      font-size:1.8rem;
      padding-bottom: 8px;
    }
    h4{
      font-size: 1.6rem;
    }
    h5{
      font-size: 1.5rem;
    }
    h6{
      font-size:1.4rem;
    }
    .column_box{
      display:flex;
      flex-flow: wrap;
      justify-content: space-between;
      margin-bottom: 50px;
      .col{
        .col_img{
          margin-top:20px;
          img{
            max-height: 360px;
          }
        }
      }
      &.col_2{
        .col{
          width:calc(100% / 2 - 30px);
          &:nth-child(n+3){
            margin-top:40px;
          }
        }
      }
      &.col_3{
        .col{
          width:calc(100% / 3 - 40px);
          &:nth-child(n+4){
            margin-top:40px;
          }
        }
        &:after{
          content:"";
          display:block;
          width:calc(100% / 3 - 40px);
        }
      }
    }

    dl{
      display:flex;
      flex-flow: wrap;
      dt{
        border-bottom:solid 1px #dddddd; 
        margin:0;
        padding: 10px 0;
        width:10%;
      }
      dd{
        border-bottom:solid 1px #dddddd; 
        padding: 10px 0 10px 30px;
        margin:0;
        width:90%;
      }
      dt:last-child{
        background: red;
        border:none;
      }
    }

  }
}

// @media only screen and (max-width: 769px) {
//   main{
//     table{
//       th,td{
//         display:block;
//         text-align:left;
//       }
//     }
//   }
// }
// 


/* ––––––––––––––––––––––––––––––––––––––––––––––––––
全頁共通デフォルトギャラリー
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.gallery{
    display: flex;
    flex-flow: wrap row;
    justify-content: space-between;
    margin:15px 0;
    br{
        display:none;
    }
    .gallery-item{
        overflow:hidden;
        position:relative;
        .gallery-icon{
            a{
                border:none;
                display:block;
                overflow:hidden;
                margin:1px;
                position: relative;
                &:before{
                    content: "";
                    display: block;
                    padding-top: 100%;
                }
                img{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width:100%;
                }
            }
        }
        .gallery-caption{
            background: rgba(0,0,0,.6);
            color: white;
            padding: 5px;
            pointer-events: none;
            position: absolute;
            left: 1px;
            right: 1px;
            bottom:0;
        }
    }
    &.gallery-size-thumbnail{
        .gallery-item{
            .gallery-icon{
                a{
                    &:before{
                        padding-top: 100%;
                    }
                    img{

                    }
                }
            }
        }
    }
    &.gallery-columns-1{
        .gallery-item{
            width: 100%;
        }
    }
    &.gallery-columns-2{
        .gallery-item{
            width: calc(100% / 2);
        }
    }
    &.gallery-columns-3{
        .gallery-item{
            width: calc(100% / 2);
        }
    }
    &.gallery-columns-4{
        .gallery-item{
            width: calc(100% / 2);
        }
    }
    &.gallery-columns-5{
        .gallery-item{
            width: calc(100% / 2);
        }
    }
    &.gallery-columns-6{
        .gallery-item{
            width: calc(100% / 2);
        }
    }
    &.gallery-columns-7{
        .gallery-item{
            width: calc(100% / 2);
        }
    }
    &.gallery-columns-8{
        .gallery-item{
            width: calc(100% / 2);
        }
    }
    &.gallery-columns-9{
        .gallery-item{
            width: calc(100% / 2);
        }
    }
}

@media only screen and (min-width: 767px) {
    .gallery{
        margin:20px 0;
        .gallery-item{
            .gallery-icon{
                a{
                    &:before{
                    }
                    img{
                    }
                }
            }
            .gallery-caption{
                font-size:1.3rem;

            }
        }
        &.gallery-columns-1{
            .gallery-item{
                width: 100%;
            }
        }
        &.gallery-columns-2{
            .gallery-item{
                width: calc(100% / 2);
            }
        }
        &.gallery-columns-3{
            .gallery-item{
                width: calc(100% / 3);
            }
        }
        &.gallery-columns-4{
            .gallery-item{
                width: calc(100% / 4);
            }
        }
        &.gallery-columns-5{
            .gallery-item{
                width: calc(100% / 5);
            }
        }
        &.gallery-columns-6{
            .gallery-item{
                width: calc(100% / 6);
            }
        }
        &.gallery-columns-7{
            .gallery-item{
                width: calc(100% / 7);
            }
        }
        &.gallery-columns-8{
            .gallery-item{
                width: calc(100% / 8);
            }
        }
        &.gallery-columns-9{
            .gallery-item{
                width: calc(100% / 9);
            }
        }
    }
}

